<template>
  <div id="s-dci">
    <Header />
    <section class="banner-solution-dci">
      <!--Banner Caption-->
      <div class="banner-caption text-center">
        <h1>全球专线组网</h1>
        <h5>
          通过吉实信息遍布全球的专线网络，即刻组建属于自己的全球业务专用网
        </h5>
      </div>
    </section>
    <section class="blogs blog-list pt-100 pb-50">
      <div class="container section-container">
        <div class="row solution-section">
          <div class="col-lg-4 col-md-4">
            <div class="heading text-left">
              <h5>整体方案，全面交付</h5>
              <h2>提供丰富的接入 PoP</h2>
              <p>提供全球众多城市、知名数据中心的直接 PoP 接入，实现统一专线接入交付</p>
              <p class="resource-list">
                <RouterLink class="resource-link" to="">当前支持的城市与数据中心</RouterLink>
              </p>
            </div>
          </div>
          <div class="col-lg-2 col-md-2">

          </div>
          <div class="col-lg-6 col-md-6">
            <img src="@/assets/images/background/solution=dci_section1.png">
          </div>
        </div>
      </div>
      <div class="container section-container">
        <div class="row solution-section">
          <div class="col-lg-6 col-md-6">
            <img src="@/assets/images/background/solution=dci_section2.jpg">
          </div>
          <div class="col-lg-2 col-md-2">
          </div>
          <div class="col-lg-4 col-md-4">
            <div class="heading text-left">
              <h5>安全可靠的连接</h5>
              <h2>环路保护，可靠连接</h2>
              <p>由多路径海底电缆组成的光纤环路网络，保护您的业务不会因为个别海底电缆故障而中断</p>
              <p class="resource-list">
                <RouterLink class="resource-link" to="">当前支持的城市与数据中心</RouterLink>
              </p>
            </div>
          </div>

        </div>
      </div>
    </section>
    <InPageContact />
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/header/index";
import Footer from "@/components/footer/index";
import InPageContact from "@/components/contactus/in-page-contact";

export default {
  name: "DCI",
  components: {
    Header,
    Footer,
    InPageContact
  }
}
</script>

<style scoped>
div.section-container {
  margin-bottom: 80px;
}
p.resource-list {
  margin: 40px 0;
}
a.resource-link {
  padding: 15px 20px;
  background: #330033;
  color: #ffffff;
  font-size: 0.8em;
  font-weight: bold;
}
div.solution-section .heading h5 {
  font-size: 0.9em;
  color: #42b983;
}
</style>