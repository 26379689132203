import Vue from 'vue'
import VueRouter from 'vue-router'
import App from './App.vue'

import HomePage from "@/views/homepage/index";
import Solutions from "@/views/solutions/index";
import Connectivity from "@/views/solutions/connectivity";
import Dataservice from "@/views/solutions/dataservice";
import Colocation from "@/views/solutions/colocation";
import Dci from "@/views/solutions/dci"
import CelluarNetwork from "@/views/solutions/celluar-network";
import BusinessNetwork from "@/views/solutions/business-network";
import ContactUs from "@/views/contact-us";

const router = new VueRouter({
    routes: [
        { path: '/', component: HomePage },
        { path: '/solutions', component: Solutions },
        { path: '/solutions/connectivity', component: Connectivity },
        { path: '/solutions/dataservice', component: Dataservice },
        { path: '/solutions/colocation', component: Colocation },
        { path: '/solutions/dci', component: Dci },
        { path: '/solutions/celluar-network', component: CelluarNetwork },
        { path: '/solutions/business-network', component: BusinessNetwork },
        { path: '/contact-us', component: ContactUs },
    ],
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return { x: 0, y: 0 };
        }
    }
})

Vue.use(VueRouter)
new Vue({
    render: h => h(App),
    router: router,
}).$mount('#app')

