<template>
  <div id="s-business-network">
    <Header color="#000000" />
    <section class="banner-solution-bn">
      <!--Banner Caption-->
      <div class="banner-caption text-center">
        <h1>全球业务组网</h1>
        <h5>
          提供灵活可扩展的全球专网、IP Transit 和 Anycast 服务<br />
          为您加速交付全球业务
        </h5>
      </div>
    </section>
    <section class="blogs blog-list pt-100 pb-50">
      <div class="container">
        <div class="row solution-section">
          <div class="col-lg-6 col-md-6">
            <div class="heading text-left">
              <h5>快速部署，专网专用</h5>
              <h2>专线+虚拟专线部署<br />在高可靠性和效率之间获得平衡</h2>
              <p>集成专线、虚拟化连接的优势，混合部署为您提供高度可靠且快速敏捷的专网组网方案</p>
              <p class="resource-list">
                <RouterLink class="resource-link" to="">获取组网技术方案</RouterLink>
              </p>
            </div>
          </div>
          <div class="col-lg-6 col-md-6">
            <img src="@/assets/images/background/solution=dci_section1.png">
          </div>
        </div>
        <div class="row solution-section">
          <div class="col-lg-6 col-md-6">
            <img src="@/assets/images/background/solution=network_section2.jpg">
          </div>
          <div class="col-lg-1 col-md-1"></div>
          <div class="col-lg-5 col-md-5">
            <div class="heading text-left">
              <h5>全球互联网覆盖</h5>
              <h2>直达全球 Tier-1 互联网骨干</h2>
              <p>通过吉实信息的全球 IP Transit 服务<br />将您的业务快速通过各大 Tier-1 网络及知名互联网交换中心 (IXP) 部署到全球，减少合约和成本压力</p>
              <p>同时提供优化的中国大陆地区 Transit，与中国电信、中国联通、中国移动直接互联，使您的业务与中国的巨大市场保持同步</p>
              <p class="resource-list">
                <RouterLink class="resource-link" to="">了解当前可用接入点</RouterLink>
              </p>
            </div>
          </div>
        </div>
        <div class="row solution-section">
          <div class="col-lg-6 col-md-6">
            <div class="heading text-left">
              <h5>简易架构，全球交付</h5>
              <h2>Anycast 网络</h2>
              <p>通过全球各大 Tier-1 网络及知名互联网交换中心 (IXP) 与海底电缆专线结合，在不改变业务部署模式的情况下，使全球接入性能显著提升</p>
              <p class="resource-list">
                <RouterLink class="resource-link" to="">了解当前可用接入点</RouterLink>
              </p>
            </div>
          </div>
          <div class="col-lg-6 col-md-6">
            <img src="@/assets/images/background/solution=network_section3.jpg">
          </div>
        </div>
      </div>
    </section>
    <InPageContact />
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/header/index";
import Footer from "@/components/footer/index";
import InPageContact from "@/components/contactus/in-page-contact";

export default {
  name: "BusinessNetwork",
  components: {
    Header,
    Footer,
    InPageContact
  }
}
</script>

<style scoped>
div.solution-section {
  margin-bottom: 150px;
}
div.solution-section .heading h5 {
  font-size: 0.9em;
  color: #42b983;
}

p.resource-list {
  margin: 40px 0;
}
a.resource-link {
  padding: 15px 20px;
  background: #330033;
  color: #ffffff;
  font-size: 0.8em;
  font-weight: bold;
}
</style>