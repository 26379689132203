<template>
  <div id="contact-app">
    <Header />
    <section class="banner-cus-case">
      <!--Banner Caption-->
      <div class="banner-caption text-center">
        <h1>联系我们</h1>
      </div>
    </section>
    <section class="blogs blog-list pt-100 pb-50">
      <div class="container">
        <div class="row">
          <div class="col">
            <div class="heading text-left contact-container">
              <h3>客户服务支持</h3>
              <p>客户服务电话: +86-21-3102-1498,100</p>
              <p>客户服务邮箱: support@flarespeed.com</p>
            </div>
            <div class="heading text-left contact-container">
              <h3>联系销售团队</h3>
              <p>客户服务电话: +86-21-3102-1498,600</p>
              <p>售前咨询邮件: inquiry@flarespeed.com</p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/header/index";
import Footer from "@/components/footer/index";


export default {
  name: "ContactUs",
  components: {
    Header,
    Footer
  }
}
</script>

<style scoped>
.contact-container h3 {
  color: #42b983;
  font-size: 1.5em;
  line-height: 2em;
}
</style>