<template>
  <div id="s-celluar-network">
    <Header />
    <section class="banner-solution-celluar-network">
      <!--Banner Caption-->
      <div class="banner-caption text-center">
        <h1>5G 专用网</h1>
        <h5>
          提供基于5G专用网络的企业专网，在性能和安全性之间获得绝佳体验
        </h5>
      </div>
    </section>
    <section class="blogs blog-list pt-100 pb-50">
      <div class="container">
        <div class="row solution-section">
          <div class="col-lg-6 col-md-6">
            <div class="heading text-left">
              <h5>专用接入，安全高效</h5>
              <h2>轻松实现业务覆盖世界</h2>
              <p>集成三大运营商和海外运营商共同覆盖，<br />由各大运营商保障您的业务覆盖世界的各个角落</p>
              <p class="resource-list">
                <RouterLink class="resource-link" to="">当前覆盖地区与运营商</RouterLink>
              </p>
            </div>
          </div>
          <div class="col-lg-6 col-md-6">
            <img src="@/assets/images/background/solution=dci_section1.png">
          </div>
        </div>
      </div>
    </section>
    <InPageContact />
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/header/index";
import Footer from "@/components/footer/index";
import InPageContact from "@/components/contactus/in-page-contact";

export default {
  name: "CelluarNetwork",
  components: {
    Header,
    Footer,
    InPageContact
  }
}
</script>

<style scoped>
p.resource-list {
  margin: 40px 0;
}
a.resource-link {
  padding: 15px 20px;
  background: #330033;
  color: #ffffff;
  font-size: 0.8em;
  font-weight: bold;
}
div.solution-section .heading h5 {
  font-size: 0.9em;
  color: #42b983;
}
</style>