<template>
  <div id="s-data-service">
    <Header />
    <section class="banner-solution-dataservice">
      <!--Banner Caption-->
      <div class="banner-caption text-center">
        <h1>交易市场数据</h1>
        <h5>
          从全球各大交易所获取实时原始数据、聚合数据和各类历史行情，帮助您的团队发现未来的商机<br />
          吉实信息提供全球多家交易所数据并通过超低延迟网络将实时行情送达您的数据中心或托管机柜
        </h5>
      </div>
    </section>
    <section class="blogs blog-list pt-100 pb-50">
      <div class="container">
        <div class="row solution-section">
          <div class="col-lg-4 col-md-4">
            <div class="heading text-left">
              <h5>整体方案，降本增速</h5>
              <h2>丰富的交易市场数据</h2>
              <p>全球市场数据是进行交易和竞争的核心资源，作为全服务提供商，吉实信息在超低延迟基础设施基础之上为您提供更低延迟更快速的数据<br />
              通过吉实信息提供的一对一解决方案，帮助您降低数据获取成本，提高数据质量。</p>
              <p class="resource-list">
                <RouterLink class="resource-link" to="">当前支持的交易所与数据服务</RouterLink>
              </p>
            </div>
          </div>
          <div class="col-lg-2 col-md-2">

          </div>
          <div class="col-lg-6 col-md-6">
            <img src="@/assets/images/background/solution=dataservice_section1.jpg">
          </div>
        </div>
      </div>
    </section>
    <InPageContact />
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/header/index";
import Footer from "@/components/footer/index";
import InPageContact from "@/components/contactus/in-page-contact";

export default {
  name: "DataService",
  components: {
    Header,
    Footer,
    InPageContact
  }
}
</script>

<style scoped>
div.heading h2 {
  margin: 15px 0 40px 0;
}
div.heading h5 {
  font-size: 0.9em;
  color: #42b983;
}
p.resource-list {
  margin: 40px 0;
}
a.resource-link {
  padding: 15px 20px;
  background: #330033;
  color: #ffffff;
  font-size: 0.8em;
  font-weight: bold;
}
div.solution-section {
  margin: 100px 0;
}
p.resource-list {
  margin: 40px 0;
}
</style>