<template>
  <div id="home">
    <Header />
    <Slider />
    <!--Services Section Start-->
    <section class="services bg-gray pt-100 pb-50" data-scroll-index="1">
      <div class="container">
        <div class="row">
          <div class="col">
            <div class="heading text-center">
              <h6>产品与服务</h6>
              <h2>我们致力于</h2>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <!--Service Item-->
            <div class="service-item">
                            <span class="icon">
                                <i class="fa fa-rocket"></i>
                            </span>
              <h4>行情、交易与连接</h4>
              <p>提供不同金融市场间具有竞争力的电信服务<br />是我们的核心使命</p>
            </div>
          </div>
          <div class="col-md-4">
            <!--Service Item-->
            <div class="service-item">
                            <span class="icon">
                                <i class="fa fa-chain"></i>
                            </span>
              <h4>次时代区块链交易</h4>
              <p>超高速网络与基础设施<br />在交易中发挥着至关重要的作用</p>
            </div>
          </div>
          <div class="col-md-4">
            <!--Service Item-->
            <div class="service-item">
                            <span class="icon">
                                <i class="fa fa-gear"></i>
                            </span>
              <h4>超大带宽连接</h4>
              <p>交易所数据中心间400Gbps起超高速低延迟连接<br />增强您的行业竞争力</p>
            </div>
          </div>
          <div class="col-md-4">
            <!--Service Item-->
            <div class="service-item">
                            <span class="icon">
                                <i class="fa fa-plug"></i>
                            </span>
              <h4>全球低延迟海缆系统</h4>
              <p>拉近各大市场间的距离，从而增强您的竞争力</p>
            </div>
          </div>
          <div class="col-md-4">
            <!--Service Item-->
            <div class="service-item">
                            <span class="icon">
                                <i class="fa fa-rocket"></i>
                            </span>
              <h4>全球应用加速</h4>
              <p>降低应用触达全球客户的延迟，提升企业服务竞争力</p>
            </div>
          </div>
          <div class="col-md-4">
            <!--Service Item-->
            <div class="service-item">
                            <span class="icon">
                                <i class="fa fa-cloud"></i>
                            </span>
              <h4>企业云服务</h4>
              <p>提供云行情产品以及传统云集成</p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 text-center">
            <!--contact button-->
            <RouterLink
                class="mt-30 main-btn"
                to="solutions">
              了解全部产品与解决方案
            </RouterLink>
          </div>
        </div>
      </div>
    </section>
    <!--Services Section End-->
    <Footer />
  </div>
</template>

<script>
import jQuery from 'jquery'
window.$ = jQuery
import Header from '@/components/header/index';
import Slider from '@/components/slider/index';
import Footer from '@/components/footer/index';
export default {
  name: "HomePage",
  components: {
    Header,
    Slider,
    Footer,
  },
  mounted() {
  }
}
</script>

<style scoped>

</style>