<template>
  <div id="slider">
    <superslide :options="options" class="slideBox">
      <div id="slides">
        <div class="slides-container">
          <img src="@/assets/images/slider/slider_images_1.jpg" alt="Slider 1" />
          <img src="@/assets/images/slider/slider_images_2.jpg" alt="Slider 2" />
        </div>
      </div>
      <div class="container">
        <!--Banner Content-->
        <div class="banner-caption">
          <h1>构建全新的</h1>
          <p class="cd-headline clip mt-30">
            <span>微波 + 光网络</span><br>

              <vue-typed-js :strings="['超低延迟交易', 'AI集群', '全球网络']">
                <span class="blc">加速您的 <span class="typing"></span></span>
              </vue-typed-js>
          </p>
        </div>
        <div class="arrow bounce">
          <a class="fa fa-chevron-down fa-2x" href="#" data-scroll-nav="1"></a>
        </div>
      </div>
    </superslide>
  </div>
</template>

<script>
import Vue from 'vue'
import { VueTypedJs } from 'vue-typed-js'
import VueSuperSlide from 'vue-superslide'

Vue.use(VueTypedJs)
Vue.use(VueSuperSlide)

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Slider",
  components: {
    VueTypedJs,
  },
  data () {
    return {
      options: {
        mainCell: "#slides .slides-container",
        autoPlay: true,
        animation: 'fade',
        play: 3000,
        interval: 8000,
      }
    }
  }
}
</script>

<style scoped>
div#slider {
  height: 100vh;
  overflow: hidden;
  position: relative;
}
div.slides-container {
  position: absolute;
  overflow: hidden;
  top: 0;
}
div.banner-caption {
  top: 50%;
  position: absolute;
  color: #ffffff;
  z-index: 11;
  width: 100%;
  transform: translateY(-50%);
}
div.banner-caption h1 {
  color: #ffffff;
  font-weight: 900;
  font-size: 5em;
}
span.blc {
  display: inline-block;
}
span.typing {
  color: #ffc8ff;
  opacity: 1;
  font-weight: 700;
  display: inline-block;
}
</style>