<template>
  <nav class="navbar navbar-expand-lg navbar-dark">
    <div class="container">
      <!-- LOGO -->
      <img
          class="fs-logo"
          :style="{filter: basicFilter}"
          src="@/assets/images/logo.svg"
      />
      <a
          class="navbar-brand logo"
          :style="{color: basicColor}"
          href="index.html">
        Flarespeed<br />
        吉实信息技术
      </a>

      <button class="navbar-toggler collapsed" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="navbar-collapse collapse" id="navbarCollapse">
        <ul class="navbar-nav ml-auto">
          <li class="nav-item">
            <RouterLink
                class="nav-link"
                :class="{ active: isActivePath('') }"
                :style="{color: basicColor}"
                to="/">首页</RouterLink>
          </li>
          <li class="nav-item">
            <RouterLink
                class="nav-link"
                :class="{ active: isActivePath('solutions') }"
                :style="{color: basicColor}"
                to="/solutions">解决方案</RouterLink>
          </li>
          <li class="nav-item">
            <RouterLink
                class="nav-link"
                :class="{ active: isActivePath('contact-us') }"
                :style="{color: basicColor}"
                to="/contact-us">联系我们</RouterLink>
          </li>
          <li class="nav-item">
            <RouterLink
                class="nav-link"
                :style="{color: basicColor}"
                to="/en">EN</RouterLink>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Header",
  data () {
    return {
      basicColor: "#ffffff",
      basicFilter: ""
    }
  },
  methods: {
    isActivePath(path) {
      let currentPath = this.$route.path.split("/")
      return currentPath[1] === path
    },
    getBasicColor() {

    }
  },
  mounted() {
    if (this.color === undefined || this.color === "#ffffff") {
      this.basicColor = "#ffffff"
      // default color
    } else {
      this.basicColor = this.color
      this.basicFilter = "none"
    }
  },
  props: {
    color: {
      type: String,
      required: false
    }
  },
}
</script>

<style scoped>
</style>