var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"s-colocation"}},[_c('Header'),_vm._m(0),_c('section',{staticClass:"blogs blog-list pt-100 pb-50"},[_c('div',{staticClass:"container"},[_vm._m(1),_c('div',{staticClass:"row solution-section"},[_c('div',{staticClass:"col-lg-4 col-md-4 certificated-list"},[_c('ul',[_c('li',[_c('FontAwesomeIcon',{attrs:{"icon":"success"}}),_vm._v(" ISO 14001")],1),_vm._m(2),_c('li',[_vm._v("PCI DSS")]),_c('li',[_vm._v("SOC 2 Type II")]),_c('li',[_vm._v("LEED")])])]),_vm._m(3),_vm._m(4)]),_vm._m(5)])]),_c('InPageContact'),_c('Footer')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"banner-solution-colocation"},[_c('div',{staticClass:"banner-caption text-center"},[_c('h1',[_vm._v("机柜托管与租赁")]),_c('h5',[_vm._v(" 提供专业的交易所内部、近交易所机机柜托管与机柜租赁服务，"),_c('br'),_vm._v("帮助客户就近接入交易所服务 ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row solution-section"},[_c('div',{staticClass:"col-lg-4 col-md-4"},[_c('div',{staticClass:"heading text-left"},[_c('h5',[_vm._v("大容量电力与带宽")]),_c('h2',[_vm._v("靠近交易所的专业托管")]),_c('p',[_vm._v("通过超低延迟和400G波分复用系统直接接入交易所，达成延迟与电力、带宽容量之间的平衡，随时扩展业务")])])]),_c('div',{staticClass:"col-lg-2 col-md-2"}),_c('div',{staticClass:"col-lg-6 col-md-6"},[_c('img',{attrs:{"src":require("@/assets/images/background/solution=connectivity_section2.jpg")}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('i',{staticClass:"fa-address-book"}),_vm._v("ISO 27001")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-lg-4 col-md-4 certificated-list"},[_c('ul',[_c('li',[_vm._v("ISO 22301")]),_c('li',[_vm._v("ISO 27017 (MS)")]),_c('li',[_vm._v("ISO 9001")]),_c('li',[_vm._v("SOC 1 Type II")]),_c('li',[_vm._v("FISC")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-lg-4 col-md-4"},[_c('div',{staticClass:"heading text-left"},[_c('h5',[_vm._v("可靠服务")]),_c('h2',[_vm._v("只选择著名数据中心提供商")]),_c('p',[_vm._v("我们的数据中心合作伙伴通过了严格的环境和能源管理标准的认证。"),_c('br'),_vm._v(" 技术参数满足金融级客户的苛刻要求。")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row solution-section"},[_c('div',{staticClass:"col-lg-4 col-md-4"},[_c('div',{staticClass:"heading text-left"},[_c('h5',[_vm._v("数据中心、办公室无缝连接")]),_c('h2',[_vm._v("裸光纤和带保护以太网")]),_c('p',[_vm._v("实现低延迟、高可靠的数据中心与开发环境互联，同时可提供独享的 Amazon AWS、Microsoft Azure、Google Cloud 专有低延迟连接。")])])]),_c('div',{staticClass:"col-lg-2 col-md-2"}),_c('div',{staticClass:"col-lg-6 col-md-6"},[_c('img',{attrs:{"src":require("@/assets/images/background/solution=connectivity_section3.jpg")}})])])
}]

export { render, staticRenderFns }