<template>
  <div id="s-connectivity">
    <Header />
    <section class="banner-solution-connectivity">
      <!--Banner Caption-->
      <div class="banner-caption text-center">
        <h1>全球超低延迟基础设施</h1>
        <h5>
          兼顾延迟与服务覆盖：我们坚持为超低延迟交易基础设施打造其基本的两个元素<br />
          通过我们的超低延迟骨干网，覆盖全球各大洲的主要交易所达成您的实时交易和行情传输
        </h5>
      </div>
    </section>
    <section class="blogs blog-list pt-100 pb-50">
      <div class="container">
        <div class="row solution-section">
          <div class="col-lg-4 col-md-4">
            <div class="heading text-left">
              <h5>全球低延迟骨干网</h5>
              <h2>Layer 1: 全球骨干</h2>
              <p>覆盖全球的交易所间低延迟互联网络，帮助您连接上海、大连、香港、新加坡、东京、伦敦、纽约、芝加哥等主要交易所和区块链交易所，</p>
              <p>Layer 1 交付全球主要高频交易股票、衍生品，期货、期权和区块链交易市场，帮助您带来业务优势，同时最大限度地减少延迟和成本。</p>
              <p class="resource-list">
                <RouterLink class="resource-link" to="">获取当前骨干网地图</RouterLink>
              </p>
            </div>
          </div>
          <div class="col-lg-2 col-md-2">

          </div>
          <div class="col-lg-6 col-md-6">
            <img src="@/assets/images/background/solution=connectivity_section1.jpg">
          </div>
        </div>
        <div class="row solution-section">
          <div class="col-lg-6 col-md-6">
            <img src="@/assets/images/background/solution=connectivity_section2.jpg">
          </div>
          <div class="col-lg-2 col-md-2">
          </div>
          <div class="col-lg-4 col-md-4">
            <div class="heading text-left">
              <h5>超越光纤的低延迟</h5>
              <h2>微波网络</h2>
              <p>通过骨干网间额外加入微波通信网络，在低延迟光纤网络之上再度优化网络延迟。</p>

            </div>
          </div>
        </div>
        <div class="row solution-section">
          <div class="col-lg-4 col-md-4">
            <div class="heading text-left">
              <h5>数据中心、办公室无缝连接</h5>
              <h2>裸光纤和带保护以太网</h2>
              <p>实现低延迟、高可靠的数据中心与开发环境互联，同时可提供独享的 Amazon AWS、Microsoft Azure、Google Cloud 专有低延迟连接。</p>
              <p class="resource-list">
                <RouterLink class="resource-link" to="">获取当前支持的城市</RouterLink>
              </p>
            </div>
          </div>
          <div class="col-lg-2 col-md-2">
          </div>
          <div class="col-lg-6 col-md-6">
            <img src="@/assets/images/background/solution=connectivity_section3.jpg">
          </div>
        </div>
      </div>
    </section>
    <InPageContact />
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/header/index";
import Footer from "@/components/footer/index";
import InPageContact from "@/components/contactus/in-page-contact";

export default {
  name: "SolutionConnectivity",
  components: {
    Header,
    Footer,
    InPageContact
  }
}
</script>

<style scoped>
div.heading h2 {
  margin: 15px 0 40px 0;
}
div.heading h5 {
  font-size: 0.9em;
  color: #42b983;
}
div.solution-section {
  margin: 100px 0;
}
p.resource-list {
  margin: 40px 0;
}
a.resource-link {
  padding: 15px 20px;
  background: #330033;
  color: #ffffff;
  font-size: 0.8em;
  font-weight: bold;
}
</style>