var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"s-business-network"}},[_c('Header',{attrs:{"color":"#000000"}}),_vm._m(0),_c('section',{staticClass:"blogs blog-list pt-100 pb-50"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row solution-section"},[_c('div',{staticClass:"col-lg-6 col-md-6"},[_c('div',{staticClass:"heading text-left"},[_c('h5',[_vm._v("快速部署，专网专用")]),_vm._m(1),_c('p',[_vm._v("集成专线、虚拟化连接的优势，混合部署为您提供高度可靠且快速敏捷的专网组网方案")]),_c('p',{staticClass:"resource-list"},[_c('RouterLink',{staticClass:"resource-link",attrs:{"to":""}},[_vm._v("获取组网技术方案")])],1)])]),_vm._m(2)]),_c('div',{staticClass:"row solution-section"},[_vm._m(3),_c('div',{staticClass:"col-lg-1 col-md-1"}),_c('div',{staticClass:"col-lg-5 col-md-5"},[_c('div',{staticClass:"heading text-left"},[_c('h5',[_vm._v("全球互联网覆盖")]),_c('h2',[_vm._v("直达全球 Tier-1 互联网骨干")]),_vm._m(4),_c('p',[_vm._v("同时提供优化的中国大陆地区 Transit，与中国电信、中国联通、中国移动直接互联，使您的业务与中国的巨大市场保持同步")]),_c('p',{staticClass:"resource-list"},[_c('RouterLink',{staticClass:"resource-link",attrs:{"to":""}},[_vm._v("了解当前可用接入点")])],1)])])]),_c('div',{staticClass:"row solution-section"},[_c('div',{staticClass:"col-lg-6 col-md-6"},[_c('div',{staticClass:"heading text-left"},[_c('h5',[_vm._v("简易架构，全球交付")]),_c('h2',[_vm._v("Anycast 网络")]),_c('p',[_vm._v("通过全球各大 Tier-1 网络及知名互联网交换中心 (IXP) 与海底电缆专线结合，在不改变业务部署模式的情况下，使全球接入性能显著提升")]),_c('p',{staticClass:"resource-list"},[_c('RouterLink',{staticClass:"resource-link",attrs:{"to":""}},[_vm._v("了解当前可用接入点")])],1)])]),_vm._m(5)])])]),_c('InPageContact'),_c('Footer')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"banner-solution-bn"},[_c('div',{staticClass:"banner-caption text-center"},[_c('h1',[_vm._v("全球业务组网")]),_c('h5',[_vm._v(" 提供灵活可扩展的全球专网、IP Transit 和 Anycast 服务"),_c('br'),_vm._v(" 为您加速交付全球业务 ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h2',[_vm._v("专线+虚拟专线部署"),_c('br'),_vm._v("在高可靠性和效率之间获得平衡")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-lg-6 col-md-6"},[_c('img',{attrs:{"src":require("@/assets/images/background/solution=dci_section1.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-lg-6 col-md-6"},[_c('img',{attrs:{"src":require("@/assets/images/background/solution=network_section2.jpg")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("通过吉实信息的全球 IP Transit 服务"),_c('br'),_vm._v("将您的业务快速通过各大 Tier-1 网络及知名互联网交换中心 (IXP) 部署到全球，减少合约和成本压力")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-lg-6 col-md-6"},[_c('img',{attrs:{"src":require("@/assets/images/background/solution=network_section3.jpg")}})])
}]

export { render, staticRenderFns }