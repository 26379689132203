<template>
  <div id="solution">
    <Header />
    <section class="banner-solution">
      <!--Banner Caption-->
      <div class="banner-caption text-center">
        <h1>产品服务与解决方案</h1>
      </div>
    </section>
    <!--List Section Starts-->
    <section class="blogs blog-list pt-100 pb-50">
      <div class="container">
        <div class="row">
          <div class="col">
            <div class="heading text-left">
              <h3>金融业</h3>
              <p>通过吉实信息技术直接接入全球各大交易所，获取行情信息并实现低延迟交易</p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-4 col-md-6">
            <!--Blogs Item-->
            <div class="blog-item">
              <div class="blog-img">
                <RouterLink to="/solutions/connectivity">
                  <img src="@/assets/images/background/solution=finconn.jpg" alt="">
                </RouterLink>
              </div>
              <div class="blog-content">
                <h3>全球超低延迟基础设施</h3>
                <p>通过遍布全球的低延迟网络，提供直接接入交易所的交易、数据和行情解决方案。帮您实现可靠并优质的全球交易网络。</p>
                <div class="blog-meta">
                  <span class="more">
                    <RouterLink to="/solutions/connectivity">交易解决方案</RouterLink>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <!--Blogs Item-->
            <div class="blog-item">
              <div class="blog-img">
                <RouterLink to="/solutions/dataservice">
                  <img src="@/assets/images/background/solution=findata.jpg" alt="">
                </RouterLink>
              </div>
              <div class="blog-content">
                <h3>全球市场数据</h3>
                <p>从全球各大交易所获取实时原始数据、聚合数据和各类历史行情，帮助您的团队发现未来的商机</p>
                <div class="blog-meta">
                  <span class="more">
                    <RouterLink to="/solutions/dataservice">了解数据服务</RouterLink>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <!--Blogs Item-->
            <div class="blog-item">
              <div class="blog-img">
                <RouterLink to="/solutions/colocation">
                  <img src="@/assets/images/background/solution=iaas.jpg" alt="">
                </RouterLink>
              </div>
              <div class="blog-content">
                <h3>托管与机柜租赁</h3>
                <p>提供专业的交易所内部、近交易所机机柜托管与机柜租赁服务，帮助客户就近接入交易所服务</p>
                <div class="blog-meta">
                  <span class="more">
                    <RouterLink to="/solutions/colocation">了解可用的交易所和托管区域</RouterLink>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="heading text-left">
              <h3>基础通信设施</h3>
              <p>稳定可靠的基础通信解决方案</p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-4 col-md-6">
            <!--Blogs Item-->
            <div class="blog-item">
              <div class="blog-img">
                <RouterLink to="/solutions/dci">
                  <img src="@/assets/images/background/solution=globalconn.png" alt="">
                </RouterLink>
              </div>
              <div class="blog-content">
                <h3>全球专线组网</h3>
                <p>通过吉实信息遍布全球的专线网络，即刻组建属于自己的全球业务专用网</p>
                <div class="blog-meta">
                  <span class="more">
                    <RouterLink to="/solutions/dci">查看可用区域</RouterLink>
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-4 col-md-6">
            <!--Blogs Item-->
            <div class="blog-item">
              <div class="blog-img">
                <RouterLink to="/solutions/celluar-network">
                  <img src="@/assets/images/background/solution=5gconn.jpg" alt="">
                </RouterLink>
              </div>
              <div class="blog-content">
                <h3>5G 专用网</h3>
                <p>提供基于5G专用网络的企业专网，在性能和安全性之间获得绝佳体验</p>
                <div class="blog-meta">
                  <span class="more">
                    <RouterLink to="/solutions/celluar-network">了解解决方案</RouterLink>
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-4 col-md-6">
            <!--Blogs Item-->
            <div class="blog-item">
              <div class="blog-img">
                <RouterLink to="/solutions/business-network">
                  <img src="@/assets/images/background/solution=wan.jpg" alt="">
                </RouterLink>
              </div>
              <div class="blog-content">
                <h3>全球业务组网</h3>
                <p>提供灵活可扩展的全球专网、IP Transit 和 Anycast 服务</p>
                <div class="blog-meta">
                  <span class="more">
                    <RouterLink to="/solutions/business-network">了解解决方案</RouterLink>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!--List Section End-->
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/header/index";
import Footer from "@/components/footer/index";
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Solution",
  components: {
    Header,
    Footer
  }
}
</script>

<style scoped>

</style>