<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App',
}
</script>

<style>
@import "@/assets/css/font-awesome.min.css";
@import "@/assets/css/bootstrap.css";
@import "@/assets/css/style.css";
@import "https://fonts.googleapis.com/css?family=Raleway:200,300,400,500,600,700,800,900%7cOpen+Sans:400,600,700,800";
</style>
