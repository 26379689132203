<template>
  <div id="in-page-contact">
    <section class="contact pt-100 pb-100" data-scroll-index="5">
      <div class="container">
        <div class="row">
          <div class="col text-center">
            <div class="heading">
              <h6>联系我们</h6>
              <h2>即刻获得解决方案</h2>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-10 offset-lg-1">
            <!--Contact Form-->
            <form id='contact-form' method='POST'><input type='hidden' name='form-name' value='contactForm' />
              <div class="row">
                <div class="col-md-6 form-group">
                  <!--name-->
                  <input type="text" class="form-control con-validate" id="contact-name" placeholder="您的名字" minlength=3 >
                </div>
                <div class="col-md-6 form-group">
                  <!--email-->
                  <input type="email" class="form-control con-validate" id="contact-email" placeholder="E-mail" >
                </div>
                <div class="col-md-12 form-group">
                  <!--message box-->
                  <textarea class="form-control con-validate" id="contact-message" placeholder="咨询内容" rows=6 ></textarea>
                </div>
                <div class="col-md-12 text-center">
                  <!--contact button-->
                  <button id="contact-submit" class="mt-30 main-btn">
                    提交
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "InPageContact"
}
</script>

<style scoped>
#in-page-contact {
  display: none;
}
</style>