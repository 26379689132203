<template>
  <div id="s-colocation">
    <Header />
    <section class="banner-solution-colocation">
      <!--Banner Caption-->
      <div class="banner-caption text-center">
        <h1>机柜托管与租赁</h1>
        <h5>
          提供专业的交易所内部、近交易所机机柜托管与机柜租赁服务，<br />帮助客户就近接入交易所服务
        </h5>
      </div>
    </section>
    <section class="blogs blog-list pt-100 pb-50">
      <div class="container">
        <div class="row solution-section">
          <div class="col-lg-4 col-md-4">
            <div class="heading text-left">
              <h5>大容量电力与带宽</h5>
              <h2>靠近交易所的专业托管</h2>
              <p>通过超低延迟和400G波分复用系统直接接入交易所，达成延迟与电力、带宽容量之间的平衡，随时扩展业务</p>
            </div>
          </div>
          <div class="col-lg-2 col-md-2">

          </div>
          <div class="col-lg-6 col-md-6">
            <img src="@/assets/images/background/solution=connectivity_section2.jpg">
          </div>
        </div>
        <div class="row solution-section">
          <div class="col-lg-4 col-md-4 certificated-list">
            <ul>
              <li>
                <FontAwesomeIcon icon="success" />
                ISO 14001</li>
              <li><i class="fa-address-book" />ISO 27001</li>
              <li>PCI DSS</li>
              <li>SOC 2 Type II</li>
              <li>LEED</li>
            </ul>
          </div>
          <div class="col-lg-4 col-md-4 certificated-list">
            <ul>
              <li>ISO 22301</li>
              <li>ISO 27017 (MS)</li>
              <li>ISO 9001</li>
              <li>SOC 1 Type II</li>
              <li>FISC</li>
            </ul>
          </div>
          <div class="col-lg-4 col-md-4">
            <div class="heading text-left">
              <h5>可靠服务</h5>
              <h2>只选择著名数据中心提供商</h2>
              <p>我们的数据中心合作伙伴通过了严格的环境和能源管理标准的认证。<br />
                技术参数满足金融级客户的苛刻要求。</p>
            </div>
          </div>
        </div>
        <div class="row solution-section">
          <div class="col-lg-4 col-md-4">
            <div class="heading text-left">
              <h5>数据中心、办公室无缝连接</h5>
              <h2>裸光纤和带保护以太网</h2>
              <p>实现低延迟、高可靠的数据中心与开发环境互联，同时可提供独享的 Amazon AWS、Microsoft Azure、Google Cloud 专有低延迟连接。</p>
            </div>
          </div>
          <div class="col-lg-2 col-md-2">
          </div>
          <div class="col-lg-6 col-md-6">
            <img src="@/assets/images/background/solution=connectivity_section3.jpg">
          </div>
        </div>
      </div>
    </section>
    <InPageContact />
    <Footer />

  </div>
</template>

<script>
import Header from "@/components/header/index";
import Footer from "@/components/footer/index";
import InPageContact from "@/components/contactus/in-page-contact";
// import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Collocation",
  components: {
    Header,
    Footer,
    InPageContact
  }
}
</script>

<style scoped>
div.heading h2 {
  margin: 15px 0 40px 0;
}
div.heading h5 {
  font-size: 0.9em;
  color: #42b983;
}
div.solution-section {
  margin: 100px 0;
}
p.resource-list {
  margin: 40px 0;
}
div.certificated-list li {
  font-size: 1.2em;
  line-height: 205%;
}
</style>